import './App.css';
import Nav from './components/header';
import Meme from './components/meme';

function App() {
  return (
    <div>
    <Nav/>
    <Meme/>
    </div>
  );
}

export default App;
