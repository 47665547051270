import React from "react"



export default function Nav(){

    return(
        <nav className="navbar">
            <img className="nav-img" alt="smirkface" src="images/smirkface.png" />
            <h2>MEME GENERATOR</h2>
        </nav>
    )
}